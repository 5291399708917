<template>
  <van-popup
    :value="value"
    position="right"
    :lazy-render='false'
    :style="{ minWidth: '335px', width: '90%', height: '100%' }"
    @click-overlay="onClose">
    <div class="transferClueBox">
      <van-form ref="transferForm" @submit.native.prevent>
        <div class="content">
          <p class="title mg_8">{{ $t('分配') }}</p>
          <p class="font-12">{{ transferSummary }}</p>
          <p class="font-12">{{ $t('【待首跟】线索：若跟进任务已逾期，分配后自动生成2小时跟进任务；跟进任务未逾期，分配后任务截止时间不变。') }}</p>
          <p class="font-12">{{ $t('【战败待审核】【无效待审核】线索：无需生成回访任务；需要您进入“审批中心”及时审批。') }}</p>
          <p class="mg_8">{{ $t('已下单线索：重新分配销售顾问后，订单的任务截止时间不变。') }}</p>
          <!-- 分配销售顾问 -->
          <div class="content_item">
            <van-cell class="requiredCell" :title="$t('分配销售顾问')"></van-cell>
            <van-radio-group v-model="formParams.assignType" direction="horizontal">
              <van-radio :name="1">{{ $t('有顾问保持不变，无顾问则分配至指定销售顾问') }}</van-radio>
              <van-radio :name="2">{{ $t('全部分配至指定销售顾问') }}</van-radio>
            </van-radio-group>
            <van-field
              v-model="formParams.assignEmpsNames"
              :label="''"
              :placeholder="$t('请选择销售顾问')"
              :required="(summaryData.noEmpCount != 0 && formParams.assignType === 1) || formParams.assignType === 2 ? true : false"
              :rules="(summaryData.noEmpCount != 0 && formParams.assignType === 1) || formParams.assignType === 2 ? [{required: true }] : []"
              @click="selectSales">
              <template #right-icon>
                <van-icon v-if="formParams.assignEmpsNames" name="close" @click.stop="onclean('assignEmpsNames')" />
                <van-icon v-else name="arrow" />
              </template>
            </van-field>
            <div class="tips">
              {{ `共${summaryData.noEmpCount}条线索无销售顾问，选择多位销售顾问时，默认轮巡平均分配` }}
            </div>
          </div>
          <!-- 线索下次跟进时间 -->
          <div v-if="showNextFollowTime" class="content_item">
            <van-cell class="requiredCell" :title="$t('【待跟进】线索下次跟进时间')"></van-cell>
            <van-radio-group v-model="formParams.nextFollowTimeType" direction="horizontal">
              <van-radio :name="1">{{ $t('指定下次跟进时间') }}</van-radio>
              <van-radio :name="2">{{ $t('保持原跟进时间不变') }}</van-radio>
            </van-radio-group>
            <van-field
              v-if="formParams.nextFollowTimeType === 1"
              v-model="formParams.nextFollowTime"
              :label="''"
              :placeholder="$t('请选择下次跟进时间')"
              required
              :rules="[{required: true }]"
              @click="selectTime('nextFollowTime')">
              <template #right-icon>
                <van-icon v-if="formParams.nextFollowTime" name="close" @click.stop="onclean('nextFollowTime')" />
                <van-icon v-else name="arrow" />
              </template>
            </van-field>
            <div class="tips">
              <span v-if="formParams.nextFollowTimeType === 1">{{ $t('若设置的时间大于线索原下次跟进时间，则以原下次跟进时间为准') }}</span>
              <span v-if="formParams.nextFollowTimeType === 2">{{ $t('若跟进任务已逾期，分配后自动生成2小时跟进任务；跟进任务未逾期，分配后任务截止时间不变。') }}</span>
            </div>
          </div>
          <!-- 战败线索回访任务 -->
          <div v-if="showDefeatVisitTime" class="content_item">
            <van-cell class="requiredCell" :title="$t('【战败】线索回访任务')"></van-cell>
            <van-radio-group v-model="formParams.defeatVisitTimeType" direction="horizontal">
              <van-radio :name="1">{{ $t('分配立即生成回访任务') }}</van-radio>
              <van-radio :name="2">{{ $t('不生成任务') }}</van-radio>
            </van-radio-group>
            <van-field
              v-if="formParams.defeatVisitTimeType === 1"
              v-model="formParams.defeatVisitTime"
              :label="''"
              :placeholder="$t('请选择回访时间')"
              required
              :rules="[{required: true }]"
              @click="selectTime('defeatVisitTime')">
              <template #right-icon>
                <van-icon v-if="formParams.defeatVisitTime" name="close" @click.stop="onclean('defeatVisitTime')" />
                <van-icon v-else name="arrow" />
              </template>
            </van-field>
          </div>
          <!-- 无效线索回访任务 -->
          <div v-if="showInvalidVisitTime" class="content_item">
            <van-cell class="requiredCell" :title="$t('【无效】线索回访任务')"></van-cell>
            <van-radio-group v-model="formParams.invalidVisitTimeType" direction="horizontal">
              <van-radio :name="1">{{ $t('分配立即生成回访任务') }}</van-radio>
              <van-radio :name="2">{{ $t('不生成任务') }}</van-radio>
            </van-radio-group>
            <van-field
              v-if="formParams.invalidVisitTimeType === 1"
              v-model="formParams.invalidVisitTime"
              :label="''"
              :placeholder="$t('请选择回访时间')"
               required
              :rules="[{required: true }]"
              @click="selectTime('invalidVisitTime')">
              <template #right-icon>
                <van-icon v-if="formParams.invalidVisitTime" name="close" @click.stop="onclean('invalidVisitTime')" />
                <van-icon v-else name="arrow" />
              </template>
            </van-field>
          </div>
          <!-- 同步企业微信好友 -->
          <div class="content_item">
            <van-cell class="requiredCell" :title="$t('同步线索企业微信好友')"></van-cell>
            <van-radio-group v-model="formParams.transferWechat" direction="horizontal">
              <van-radio :name="1">{{ $t('同步转移') }}</van-radio>
              <van-radio :name="0">{{ $t('不转移') }}</van-radio>
            </van-radio-group>
            <div class="tips">
              {{ $t('同步转移企业微信好友关系会将原顾问跟该线索的企业微信好友关系解除，新顾问自动添加该客户好友（若客户拒绝则添加失败）') }}
            </div>
          </div>
          <!-- 分配后生成的跟进任务标记 -->
          <div class="content_item">
            <van-cell class="requiredCell noRequired" :title="$t('分配后生成的跟进任务标记')"></van-cell>
            <van-field
              v-model="formParams.taskTag"
              class="taskTagField"
              :label="''"
              rows="3"
              autosize
              maxlength="100"
              show-word-limit
              type="textarea"
              :placeholder="$t('可标记该批任务为某活动邀约等')">
            </van-field>
          </div>
        </div>
        <van-popup v-model="showDatePicker" position="bottom" get-container="body">
          <van-datetime-picker
            v-if="showDatePicker"
            type="date"
            :title="$t('选择日期')"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirm"
          />
        </van-popup>
        <div class="common-footer">
          <van-button class="btn back" @click="onClose">{{$t('取消')}}</van-button>
          <van-button class="btn submit" type="submit" :loading="submitLoading" @click="onSubmit">{{ $t('确定') }}</van-button>
        </div>
      </van-form>
    </div>
    <!-- 员工列表面板 -->
    <BusinessFilters
      v-if="showDistribute"
      ref="filter"
      v-model="showDistribute"
      :options="distributionOptions"
      :multiple="true"
      :defaultActiveKey="['saIdList']"
      :buttonNameReset="$t('取消')"
      @reset="showDistribute = false"
      @submit="distributionFilter"
    >
    </BusinessFilters>
  </van-popup>
</template>
<script>
import dayjs from 'dayjs'
import clueServices from '@/services/clueServices'
import loading from '@/utils/loading'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import Item from '@/modules/stock/components/item.vue'
export default {
  components: {
    BusinessFilters
  },
  props: {
    value: {
      // 是否显示
      type: Boolean,
      default: false,
    },
    distributionIds: {
      type: Array,
      default: []
    },
    distributionRole: {
      type: String,
      default: ''  
    }
  },
  data() {
    return {
      submitLoading: false,
      summaryData: {
        defeatCount: 0,
        defeatViewCount: 0,
        firstFollowCount: 0,
        followCount: 0,
        invalidCount: 0,
        invalidViewCount: 0,
        noEmpCount: 0,
        orderCount: 0
      },
      formParams: {
        assignType: 1,
        assignEmpsNames: '',
        assignEmps: [],
        nextFollowTimeType: 1, // 1 指定下次跟进时间 2 保持原下次跟进时间不变
        nextFollowTime: '',
        defeatVisitTimeType: 1, // 战败 1分配立即生成回访任务 2不生成回访任务
        defeatVisitTime: '',
        invalidVisitTimeType: 1, // 无效 1分配立即生成回访任务 2不生成回访任务
        invalidVisitTime: '',
        transferWechat: 1, // 同步转移企业微信好友 1转移 0不转移
        taskTag: '', // 跟进任务
      },
      showDatePicker: false,
      minDate: new Date(),
      maxDate: new Date(dayjs().add(60, 'days')),
      selectTimeType: '',
      showDistribute: false
    }
  },
  computed: {
    transferSummary() {
      const { defeatCount=0, defeatViewCount=0, firstFollowCount=0, followCount=0, invalidCount=0, invalidViewCount=0, orderCount=0 } = this.summaryData
      const str = `共选择 ${this.distributionIds.length} 条数据，其中 ${firstFollowCount} 条待首跟，${followCount} 条待跟进（未排程/已排程/已试驾），
      ${defeatCount} 条战败（其中 ${defeatViewCount} 条为战败待审核），${invalidCount} 条无效（其中 ${invalidViewCount} 条为无效待审核），${orderCount} 条已下单`
      return str
    },
    distributionOptions() {
      return [
        {
          name: '',
          type: 'B_PEOPLE_SELECTION',
          field: 'saIdList',
          isSelection: true,
          valueType: 'object',
          shopIds: '',
          height: '80vh',
          roleCodes: this.distributionRole,
        },
      ]
    },
    showNextFollowTime() {
      return this.summaryData.followCount > 0
    },
    showDefeatVisitTime() {
      return this.summaryData.defeatCount - this.summaryData.defeatViewCount > 0
    },
    showInvalidVisitTime() {
      return this.summaryData.invalidCount - this.summaryData.invalidViewCount > 0
    }
  },
  watch: {
    'formParams.nextFollowTimeType': {
      handler(val) {
        if (val === 2) this.formParams.nextFollowTime = ''
      },
      immediate: true
    },
    'formParams.defeatVisitTimeType': {
      handler(val) {
        if (val === 2) this.formParams.defeatVisitTime = ''
      },
      immediate: true
    },
    'formParams.invalidVisitTimeType': {
      handler(val) {
        if (val === 2) this.formParams.invalidVisitTime = ''
      },
      immediate: true
    }
  },
  mounted() {
    this.getSummaryData()
  },
  methods: {
    // 线索转移描述
    async getSummaryData() {
      loading.showLoading()
      const res = await clueServices.transferSummary(this.distributionIds)
      loading.hideLoading()
      this.summaryData = Object.assign(this,this.summaryData, res)
    },
    selectTime(type) {
      this.selectTimeType = type
      this.showDatePicker = true
    },
    // 选择时间
    onConfirm(time) {
      this.formParams[this.selectTimeType] = dayjs(time).format('YYYY-MM-DD')
      this.showDatePicker = false
    },
    // 选择销售顾问
    selectSales() {
      this.showDistribute = true
    },
    async distributionFilter({ saIdList }) {
      this.formParams.assignEmps = saIdList.map(({ id, name }) => {
        return {
          empId: id,
          empName: name
        }
      })
      this.formParams.assignEmpsNames = this.formParams.assignEmps.map(({ empName }) => { return empName }).join(',')
    },
    // 清空已选择
    onclean(type) {
      this.formParams[type] = ''
      if (type === 'assignEmpsNames') {
        this.formParams.assignEmps = []
      }
    },
    // 关闭
    onClose() {
      this.$emit('close', false)
    },
    // 确定
    async onSubmit() {
      await this.$refs.transferForm.validate()
      const formParamsObj = JSON.parse(JSON.stringify(this.formParams))
      const {
        assignType,
        assignEmps,
        nextFollowTimeType,
        nextFollowTime,
        defeatVisitTimeType,
        defeatVisitTime,
        invalidVisitTimeType,
        invalidVisitTime,
        transferWechat,
        taskTag
      } = formParamsObj
      let params = {
        assignEmps,
        assignType,
        transferWechat,
        roleLogo: this.distributionRole,
        taskTag,
        ids: this.distributionIds
      }
      if (this.showNextFollowTime && nextFollowTimeType === 1) {
        params.nextFollowTime = dayjs(nextFollowTime).format('YYYY-MM-DD 23:59:59')
      }
      if (this.showDefeatVisitTime && defeatVisitTimeType === 1) {
        params.defeatVisitTime = dayjs(defeatVisitTime).format('YYYY-MM-DD 23:59:59')
      }
      if (this.showInvalidVisitTime && invalidVisitTimeType === 1) {
        params.invalidVisitTime = dayjs(invalidVisitTime).format('YYYY-MM-DD 23:59:59')
      }
      loading.showLoading()
      await clueServices.clueDistributionV2(params)
      loading.hideLoading()
      this.$emit('transferConfirm')
    },
  }
}
</script>
<style lang="less" scoped>
.transferClueBox{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 96px;
  .mg_8{
    margin-bottom: 8px
  }
  .van-form{
    flex: 1;
    display: flex;
    overflow: hidden;
  }
  .content{
    flex: 1;
    overflow-y: auto;
    display: flex;
    padding: 0 16px 16px 16px;
    font-size: 13px;
    flex-direction: column;
    .font-12{
      font-size: 12px;
    }
    .title{
      font-size: 14px;
      font-weight: 600;
      padding: 16px 0 8px;
      border-bottom: 1px solid #ebedf0;
    }
    .content_item{
      .taskTagField{
        border: 1px solid #ebedf0
      }
      .requiredCell{
        padding: 10px 0;
        font-weight: 600;
        font-size: 13px;
        &.van-cell::after{
          display: none
        }
        &.noRequired{
          .van-cell__title{
            span::after{
              content: ' ';
            }
          }
        }
        .van-cell__title{
          span::after{
            margin-left: 2px;
            color: #EED484;
            font-size: 14px;
            content: '*';
            align-self: baseline;
          }
        }
      }
      .tips{
        font-size: 12px;
        color: #B9921A;
        margin-top: 8px;
        padding-left: 16px
      }
    }
  }
  .common-footer{
    max-width: 100%
  }
}
</style>