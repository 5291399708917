import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index'

const ClueDistributionServices = {
  /**
   * 获取留资/预约试驾 count
   */
  async getClueDistributionCount(params) {
    const result = await request.post(`${SERVER.LEAD_SERVICE}/api/v1/assignListCount`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  },

  /**
   * 获取线索列表
   */
  async getclueList(params){
    const result = await request.post(`${SERVER.LEAD_SERVICE}/api/v1/toAssignList`, params)
    const { code, msg, data } = result
    if (code !== 0) {
      Toast.fail(msg || '获取数据失败')
      return {}
    }
    return data
  },

  /**
   * 分配线索
   * @param {*} params 
   */
  // async handleDistribution(params){
  //   const result = await request.post(`${SERVER.LEAD_SERVICE}/api/v1/assign`, params)
  //   const { code, msg, data } = result
  //   if (code !== 0) {
  //     Toast.fail(msg || '线索分配失败')
  //     return {}
  //   }
  //   return data
  // },
  // 分配线索
  handleDistribution(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/assign`, params).then(thenResolve)
  },
  // 共享数量
  getShareClueCount(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/shareCount`, params).then(thenResolve)
  },
  // 查询店内已回收线索
  getShareClueList(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/shares`, params).then(thenResolve)
  },
  // 分配店内公共线索
  setAssignRecycle(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/assignRecycle`, params).then(thenResolve)
  },
  // 分配同城共享线索
  setAssignCityShare(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/assignCityShare`, params).then(thenResolve)
  },
  // 领取回收线索线索
  setReceiveRecycle(params) {
    return request.get(`${SERVER.LEAD_SERVICE}/api/v1/receiveRecycle`, { params }).then(thenResolve)
  },
  // 城市共享线索领取前检查
  assignCityShareCheck(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/api/v1/assignCityShareCheck`,params).then(thenResolve)
  },
  // 获取分类数据
  toBeAllocatedLeadCount(params) {
    return request.post(`${SERVER.LEAD_SERVICE}/v1/lead/toBeAllocatedLeadCount`, params).then(thenResolve)
  }
}

export default ClueDistributionServices
